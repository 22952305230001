import React, {Ref, useEffect, useRef, useState} from 'react'
import {Column, useTable, useResizeColumns, useFlexLayout} from 'react-table'
import {Styles} from '../components/Table_Style'
import {KTSVG} from '../../../_metronic/helpers/components/KTSVG'
import {pushUniqueValue} from '../components/PushUniqueValue'
import {checkValueExists} from '../components/CheckValueExists'
import {tickAllCheckbox} from '../components/TickAllCheckbox'
import ExportWithdrawal from './components/exportWithdrawal'
import {Filter_Pending} from './components/filter_pending'
import {RejectionModal} from './components/rejection_modal'
import {ApprovalModal} from './components/approval_modal'
import {OverlayTrigger} from 'react-bootstrap'
import popover from '../components/Popover'
import {actionBtnCss} from './components/actionBtnCss'
import {exportActionBtnCss} from './components/exportActionBtnCss'
import Withdrawal_Export_CSV from './components/Withdrawal_Export_CSV'
import {metricsArray} from '../components/MetricsArray'
import FormatNumber from '../components/FormatNumber'
import {EmulatorUpdateWithdrawalModal} from './components/Emulator_Update_Withdrawal_Modal'
import scrollToTop from '../components/ScrollToTop'
import {MatchSMSModal} from './components/MatchSMSModal'

const Withdrawal_Pending_List: React.FC = () => {
  //States
  const [currentWalletCd, setCurrentWalletCd] = useState('')
  const [formData, setFormData] = useState([])
  const [selectedArray, setSelectedArray] = useState([])
  const [showMetrics, setShowMetrics] = useState(false)
  const [currentRejectedId, setCurrentRejectedId] = useState('')
  const [test, setTest] = useState(Boolean)
  const [metrics, setMatrics] = useState<any>(undefined)
  const [currentPage, setCurrentPage] = useState(0)
  const [totalFormData, setTotalFormData] = useState(0)
  const [filterValues, setFilterValues] = useState<any>([])
  const [downloadLoading, setDownloadLoading] = useState(false)
  const [selectedRow, setSelectedRow] = useState<any>({
    walletAccNo: '',
    walletCd: '',
    withdrawalStatus: '',
    merchantTxnId: '',
  })

  //Refs
  const approvalRemark = useRef<any>()
  const rejectionRemark = useRef<any>()
  const withdrawalFilterSubmit = useRef<any>()

  useEffect(() => {
    let metrics = localStorage.getItem('withdrawalPendingMetrics')
    if (metrics) {
      setMatrics(JSON.parse(metrics))
    } else {
      setMatrics([])
    }
  }, [formData, showMetrics])

  //Table::START
  interface Data {
    selection: string
    id: string
    withdrawalDT: string
    merchantName: string
    merchantCd: string
    customerCd: string
    destinationWalletAcc: string
    destinationWalletName: string
    walletCd: string
    amount: string
    withdrawalStatus: string
    callbackStatus: string
    withdrawalFee: string
    approvalRejectionRemark: string
    merchantTxnId: string
    reference: string
    completedDT: string
    withdrawalStatusUpdateDT: string
    approvedBy: string
  }

  const columns = React.useMemo<Column<Data>[]>(
    () => [
      {
        id: 'selection',
        Header: () => {
          return (
            //Check All Checkbox
            <div className='form-check form-check-custom form-check-solid'>
              <input
                className='form-check-input'
                title={'Select All ' + formData.length + ' Wallets'}
                style={{
                  cursor: 'pointer',
                }}
                type='checkbox'
                value=''
                id='checkAll'
                onChange={() => {
                  tickAllCheckbox(selectedArray, formData, setSelectedArray)
                  setTest(!test)
                }}
                checked={selectedArray.length > 0 && selectedArray.length === formData.length}
              />
            </div>
          )
        },
        width: 50,
        accessor: (formData: any) => {
          return (
            <div className='form-check form-check-custom form-check-solid'>
              <input
                className='form-check-input'
                style={{
                  cursor: 'pointer',
                }}
                type='checkbox'
                value=''
                id={'checkbox_' + formData.walletAccNo}
                onChange={() => {
                  pushUniqueValue(selectedArray, formData, setSelectedArray)
                  setTest(!test)
                }}
                checked={checkValueExists(selectedArray, formData.id)}
              />
            </div>
          )
        },
      },
      {
        Header: 'Withdrawal Request ID',
        accessor: 'id',
      },
      {
        Header: 'Withdrawal DateTime',
        accessor: (row) => {
          return row.withdrawalDT.replace('T', ' ').replace(/\.\d+/, '')
        },
      },
      {
        Header: 'Completed DateTime',
        accessor: (row: any) => {
          return row.completedDT.replace('T', ' ').replace(/\.\d+/, '')
        },
      },
      {
        Header: 'Status Updated DT',
        accessor: (row: any) => {
          return row.withdrawalStatusUpdateDT.replace('T', ' ').replace(/\.\d+/, '')
        },
      },
      {
        Header: 'Merchant',
        accessor: (row: any) => {
          return row.merchantName + ' - ' + row.merchantCd
        },
      },
      {
        Header: 'Customer Code',
        accessor: 'customerCd',
      },

      {
        Header: 'Destination Account No',
        accessor: 'destinationWalletAcc',
      },
      {
        Header: 'Destination Account Name',
        accessor: 'destinationWalletName',
      },
      {
        Header: 'Wallet Code',
        accessor: 'walletCd',
      },
      {
        Header: 'Amount',
        accessor: (row: any) => {
          return FormatNumber(row.amount)
        },
      },
      {
        Header: 'Status',
        accessor: 'withdrawalStatus',
      },
      {
        Header: 'Result/ Response Status',
        accessor: 'callbackStatus',
      },
      {
        Header: 'Withdrawal Fee',
        accessor: (row: any) => {
          return FormatNumber(row.withdrawalFee)
        },
      },
      {
        Header: 'Approval/ Rejection Remark',
        accessor: 'approvalRejectionRemark',
      },
      {
        Header: 'Merchant Transaction ID',
        accessor: 'merchantTxnId',
      },
      {
        Header: 'Reference',
        accessor: 'reference',
      },
      {
        Header: 'Approved By',
        accessor: 'approvedBy',
      },
      {
        Header: 'Action',
        accessor: (row: any) => {
          return (
            <div className='d-flex'>
              <button
                type='button'
                className='btn btn-icon btn-bg-warning btn-active-color-white btn-sm me-2'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_emulator_update_withdrawal'
                onClick={() => {
                  setSelectedRow(row)
                }}
                style={
                  row.withdrawalStatus === 'PENDING' ||
                  row.withdrawalStatus === 'PENDING-BOT' ||
                  row.withdrawalStatus === 'IN-PROGRESS-BOT'
                    ? {visibility: 'visible', opacity: '1'}
                    : {visibility: 'hidden', opacity: '0'}
                }
              >
                {' '}
                <OverlayTrigger
                  trigger='hover'
                  placement='top'
                  overlay={popover('Reassign Withdrawal')}
                >
                  <div
                    style={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  >
                    <i className='bi bi-lightning-charge-fill'></i>
                  </div>
                </OverlayTrigger>{' '}
              </button>
              <button
                type='button'
                className='btn btn-icon btn-bg-success btn-active-color-white btn-sm me-2'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_export'
                onClick={() => {
                  pushUniqueValue(selectedArray, row, setSelectedArray)
                }}
                style={exportActionBtnCss(row.withdrawalStatus)}
              >
                {' '}
                <OverlayTrigger
                  trigger='hover'
                  placement='top'
                  overlay={popover('Export Withdrawal')}
                >
                  <div
                    style={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  >
                    <i className='bi bi-lightning-charge-fill'></i>
                  </div>
                </OverlayTrigger>{' '}
              </button>
              <button
                type='button'
                className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-2'
                data-bs-toggle='modal'
                data-bs-target='#staticBackdrop_approve'
                onClick={() => {
                  setCurrentRejectedId(row.id)
                  setCurrentWalletCd(row.walletCd)
                }}
                style={actionBtnCss(row.withdrawalStatus)}
              >
                {' '}
                <OverlayTrigger
                  trigger='hover'
                  placement='top'
                  overlay={popover('Approve Withdrawal')}
                >
                  <div
                    style={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr012.svg' className='svg-icon-3' />
                  </div>
                </OverlayTrigger>{' '}
              </button>
              <button
                type='button'
                className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-2'
                data-bs-toggle='modal'
                data-bs-target='#staticBackdrop_reject'
                onClick={() => {
                  setCurrentRejectedId(row.id)
                  setCurrentWalletCd(row.walletCd)
                }}
                style={actionBtnCss(row.withdrawalStatus)}
              >
                {' '}
                <OverlayTrigger
                  trigger='hover'
                  placement='top'
                  overlay={popover('Reject Withdrawal')}
                >
                  <div
                    style={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr011.svg' className='svg-icon-3' />
                  </div>
                </OverlayTrigger>{' '}
              </button>
            </div>
          )
        },
        width: 200,
      },
    ],
    [test, selectedArray]
  )

  interface TableProps {
    columns: Column<Data>[]
    data: Data[]
  }

  interface CheckboxProps {
    indeterminate?: boolean
    [x: string]: any
  }

  type Props = React.InputHTMLAttributes<HTMLInputElement> & {
    indeterminate?: boolean
  }

  const IndeterminateCheckbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
    ({indeterminate, ...rest}: Props, ref: Ref<HTMLInputElement>) => {
      const defaultRef = React.useRef<HTMLInputElement>(null)
      const resolvedRef: any = ref || defaultRef

      React.useEffect(() => {
        if (resolvedRef.current) {
          resolvedRef.current.indeterminate = indeterminate || false
        }
      }, [resolvedRef, indeterminate])

      return <input className='form-check-input me-3' type='checkbox' ref={resolvedRef} {...rest} />
    }
  )

  function Table({columns, data}: TableProps) {
    const defaultColumn = React.useMemo(
      () => ({
        minWidth: 30,
        width: 150,
        maxWidth: 400,
      }),
      []
    )

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state,
      allColumns,
      getToggleHideAllColumnsProps,
    } = useTable(
      {
        columns,
        data,
        defaultColumn,
        initialState: {hiddenColumns: metrics ? metrics : []},
      },
      useFlexLayout,
      useResizeColumns
    )

    return (
      <>
        <div>
          <div
            className='card card-custom card-flush mb-5'
            id='selectMetricsWrapper'
            style={{
              display: showMetrics ? 'block' : 'none',
            }}
          >
            <div className='card-body py-5'>
              <div className='row row-cols-4 ms-3 justify-content-center'>
                <div className='col form-check form-check-custom form-check-solid form-check-sm w-200px'>
                  <div className='form-check-label d-flex m-5 fw-bold'>
                    <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} /> Toggle All
                  </div>
                </div>
                {allColumns
                  .filter(
                    (column) =>
                      column.Header !== ' ' &&
                      column.Header !== 'Action' &&
                      column.id !== 'selection'
                  )
                  .map((column) => (
                    <div className='col form-check form-check-custom form-check-solid form-check-sm w-200px'>
                      <div key={column.id}>
                        <label className='form-check-label d-flex justify-content-center align-items-center m-5'>
                          <input
                            className='form-check-input me-3'
                            type='checkbox'
                            onClick={() => {
                              metricsArray('withdrawalPendingMetrics', column.id)
                            }}
                            {...column.getToggleHiddenProps()}
                          />{' '}
                          {String(column.Header)}
                        </label>
                      </div>
                    </div>
                  ))}
                <br />
              </div>
            </div>
          </div>
          <div className='card card-custom p-5'>
            {formData.length > 0 ? (
              <div className='table-responsive mb-5'>
                <div {...getTableProps()} className='table' style={{width: '100%'}}>
                  <div>
                    {headerGroups.map((headerGroup) => (
                      <div
                        {...headerGroup.getHeaderGroupProps()}
                        className='tr fw-bold fs-6 text-gray-800 border-bottom border-gray-200'
                      >
                        {headerGroup.headers.map((column: any) => (
                          <div {...column.getHeaderProps()} className='th'>
                            {column.render('Header')}
                            {/* Use column.getResizerProps to hook up the events correctly */}
                            <div
                              {...(column as any).getResizerProps()}
                              className={`resizer ${
                                (column as any).isResizing ? 'isResizing' : ''
                              }`}
                            />
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>

                  <div {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                      prepareRow(row)
                      return (
                        <div {...row.getRowProps()} className='tr'>
                          {row.cells.map((cell: any) => {
                            return (
                              <div {...cell.getCellProps()} className='td'>
                                {cell.render('Cell')}
                              </div>
                            )
                          })}
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            ) : (
              <div className='text-center'>No Data Found</div>
            )}
          </div>
        </div>
      </>
    )
  }
  //Table::END

  return (
    <div>
      <div className='d-flex justify-content-between mb-5'>
        {/* <FilterButton /> */}
        <div className='m-0 d-flex'>
          <div className='fv-row me-5'>
            <button
              className='btn btn-info btn-sm fw-bold btn-color-white-700 w-100 '
              disabled={formData.length > 0 && !downloadLoading ? false : true}
              onClick={() => {
                Withdrawal_Export_CSV(filterValues, setDownloadLoading)
              }}
            >
              {!downloadLoading ? 'Download Content as CSV' : 'Downloading...Please Wait!'}
            </button>
          </div>
          <button
            className='btn btn-sm btn-flex fw-bold btn-color-white-700 btn-warning me-5'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_export'
          >
            Export Withdrawal
          </button>{' '}
        </div>
        <div className='m-0 d-flex '>
          <button
            type='button'
            className='btn btn-sm btn-flex fw-bold rotate'
            onClick={(e) => {
              setShowMetrics(!showMetrics)
              e.currentTarget.classList.toggle('active')
            }}
          >
            <KTSVG
              path='/media/icons/duotune/arrows/arr004.svg'
              className='svg-icon-6 svg-icon-muted me-1 rotate-180'
            />{' '}
            Select Metrics
          </button>
        </div>
      </div>{' '}
      <Filter_Pending
        setFormData={setFormData}
        setSelectedArray={setSelectedArray}
        withdrawalFilterSubmit={withdrawalFilterSubmit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setTotalFormData={setTotalFormData}
        setFilterValues={setFilterValues}
        downloadLoading={downloadLoading}
      />
      <MatchSMSModal />
      <Styles>
        <Table columns={columns} data={formData == undefined ? [] : formData} />
      </Styles>
      {/* Pagination START */}
      <div className='mt-5 d-flex justify-content-center align-items-center'>
        <span className='me-5'>Total: {totalFormData}</span>
        <button
          className='btn btn-secondary p-2 me-2 w-100px h-30px'
          disabled={currentPage === 1}
          onClick={() => {
            setCurrentPage(currentPage - 1)
            scrollToTop()
          }}
        >
          Previous
        </button>
        {currentPage == 0 ? (
          <div>1 of {Math.ceil(totalFormData / 200)}</div>
        ) : (
          <div>
            {currentPage} of {Math.ceil(totalFormData / 200)}
          </div>
        )}
        <button
          className='btn btn-secondary p-2 ms-2 w-100px h-30px'
          disabled={currentPage >= Math.ceil(totalFormData / 200)}
          onClick={() => {
            if (currentPage == 0) {
              setCurrentPage(2)
            } else {
              setCurrentPage(currentPage + 1)
            }
            scrollToTop()
          }}
        >
          Next
        </button>
        <select
          onChange={(e) => setCurrentPage(Number(e.target.value))}
          value={currentPage}
          className='btn btn-secondary p-2 ms-2 w-100px h-30px'
        >
          {Array.from({length: Math.ceil(totalFormData / 200)}, (_, i) => (
            <option key={i + 1} value={i + 1}>
              Page {i + 1}
            </option>
          ))}
        </select>
      </div>
      {/* Pagination END */}
      {/* Modal */}
      <ApprovalModal
        currentRejectedId={currentRejectedId}
        approvalRemark={approvalRemark}
        currentWalletCd={currentWalletCd}
        withdrawalFilterSubmit={withdrawalFilterSubmit}
      />
      <RejectionModal
        currentRejectedId={currentRejectedId}
        rejectionRemark={rejectionRemark}
        withdrawalFilterSubmit={withdrawalFilterSubmit}
      />
      {/* Modal */}
      <ExportWithdrawal
        selectedArray={selectedArray}
        setSelectedArray={setSelectedArray}
        withdrawalFilterSubmit={withdrawalFilterSubmit}
      />
      <EmulatorUpdateWithdrawalModal
        selectedRow={selectedRow}
        withdrawalFilterSubmit={withdrawalFilterSubmit}
      />
    </div>
  )
}

export default Withdrawal_Pending_List
